.custom-link {
    text-decoration: none; /* Remove underline */
  }
  .images {
     /* height: 165px !important; */
    /* object-fit: fill !important; */
     width: 295px !important; 
    height:165px !important;
    object-fit: scale-down !important;
    max-width: 100%;
    cursor: pointer;
    
  }
  .imagesNav {

   object-fit: scale-down !important;
   max-width: 100%;
   
 }