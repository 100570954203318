ol {
  margin: 0;
  padding: 0;
}

table td,
table th {
  padding: 0;
}

.c16 {
  vertical-align: middle;
  background-color: #bfbfbf;
  width: 221.7pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c55 {
  vertical-align: middle;
  width: 28.7pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c90 {
  vertical-align: middle;
  width: 90.4pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c38 {
  vertical-align: middle;
  width: 248.6pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c58 {
  vertical-align: middle;
  width: 243.5pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c67 {
  vertical-align: middle;
  width: 68.7pt;
  border-color: #000;
  border-style: solid;
  border-width: 0 1pt 0 0;
}

.c11 {
  vertical-align: middle;
  width: 174.8pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c0 {
  vertical-align: middle;
  width: 32pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c34 {
  vertical-align: middle;
  width: 131.5pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c110 {
  vertical-align: middle;
  width: 276pt;
  border-color: #000;
  border-style: solid;
  border-width: 0;
}

.c25 {
  vertical-align: middle;
  width: 117.1pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c45 {
  vertical-align: middle;
  width: 259.2pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c76 {
  vertical-align: middle;
  width: 4.3pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c3 {
  vertical-align: middle;
  width: 31pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c105 {
  vertical-align: middle;
  width: 163.6pt;
  padding: 0 4px;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c39 {
  vertical-align: middle;
  width: 413.1pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c21 {
  vertical-align: middle;
  width: 102pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c23 {
  vertical-align: middle;
  width: 31.3pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c20 {
  vertical-align: middle;
  width: 31.2pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c65 {
  vertical-align: middle;
  width: 227.8pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c88 {
  vertical-align: middle;
  width: 73.2pt;
  border-color: #000;
  border-style: dotted dotted solid;
  border-width: 1pt 1pt 0;
}

.c79 {
  vertical-align: middle;
  width: 113.1pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c116 {
  vertical-align: middle;
  width: 174.8pt;
  border-color: #000;
  border-style: solid;
  border-width: 0 0 1pt;
}

.c44 {
  vertical-align: middle;
  width: 74.2pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c80 {
  vertical-align: middle;
  width: 146.1pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c7 {
  vertical-align: middle;
  /* width: 32.5pt; */
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c26 {
  vertical-align: middle;
  width: 137.8pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c117 {
  vertical-align: middle;
  width: 68.7pt;
  border-color: #000;
  border-style: solid;
  border-width: 0 1pt 1pt 0;
}

.c103 {
  vertical-align: middle;
  width: 174.8pt;
  border-color: #000;
  border-style: solid;
  border-width: 0;
}

.c75 {
  vertical-align: middle;
  width: 32.5pt;
  border-color: #000;
  border-style: solid;
  border-width: 0 0 1pt;
}

.c51 {
  vertical-align: middle;
  width: 77.5pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c13 {
  vertical-align: middle;
  width: 68.7pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c54 {
  vertical-align: middle;
  width: 71.8pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c42 {
  vertical-align: middle;
  width: 48pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c46 {
  vertical-align: middle;
  width: 36.5pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c31 {
  vertical-align: middle;
  width: 73.2pt;
  border-color: #000;
  border-style: solid dotted dotted;
  border-width: 0 1pt 1pt;
}

.c4 {
  vertical-align: middle;
  width: 35.8pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c57 {
  vertical-align: middle;
  width: 340pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c56 {
  vertical-align: middle;
  width: 33pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c17 {
  vertical-align: middle;
  width: 34.6pt;
  border-color: #000;
  border-style: solid;
  border-width: 1pt;
}

.c41 {
  vertical-align: middle;
  width: 52pt;
  border-color: #000;
  border-style: dotted;
  border-width: 1pt;
}

.c68 {
  -webkit-text-decoration-skip: none;
  color: #000;
  font-weight: 700;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal;
}

.c27 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c22 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  text-wrap: wrap;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal;
}

.c108 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 5.5pt;
  font-family: "Arial";
  font-style: normal;
}

.c102 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c35 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c47 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c9 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c2 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "Times New Roman";
  font-style: normal;
  font-weight: bold;
}

.c30 {
  flex-grow: 1;
  border-bottom: 1px solid #000;
  border-collapse: collapse;
}

.c104 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 7pt;
  font-family: "Times New Roman";
  font-style: normal;
}

.c49 {
  margin-left: 7.3pt;
  text-indent: -0;
  padding-bottom: 0;
  line-height: 1;
  text-align: center;
  margin-right: 6.7pt;
}

.c94 {
  margin-left: 58.5pt;
  
  text-indent: -27pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  margin-right: 9.2pt;
}

.c10 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 9pt;
  font-family: "Arial";
  font-style: normal;
}

.c113 {
  color: #000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 8pt;
  font-family: "Arial";
  font-style: normal;
}

.c61 {
  color: #000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}

.c77 {
  margin-left: 5.4pt;
  
  padding-bottom: 0;
  line-height: 0.93333333333333;
  text-align: left;
  margin-right: 8.4pt;
}

.c78 {
  margin-left: 13.4pt;
  
  text-indent: -9pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c100 {
  
  padding-left: 13.6pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c74 {
  margin-left: 9.8pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: center;
  margin-right: 9.2pt;
}

.c92 {
  line-height: 1;
  text-align: center;
  transform: rotate(-90deg);
}

.c24 {
  margin-left: 22.6pt;
  
  padding-left: 14.5pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c62 {
  margin-left: 11.4pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  margin-right: 4.2pt;
}

.c84 {
  padding-bottom: 0;
  line-height: 1;
  text-align: right;
}

.c64 {
  -webkit-text-decoration-skip: none;
  font-weight: 400;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: "Times New Roman";
}

.c37 {
  margin-left: 6pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c66 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c71 {
  margin-left: 4.4pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c83 {
  margin-left: 4.5pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c70 {
  padding-top: 20pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: right;
  margin-right: 4.8pt;
}

.c53 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c85 {
  margin-left: 0.5pt;
  
  padding-bottom: 0;
  line-height: 0.77916666666667;
  text-align: center;
}

.c118 {
  margin-left: 11.4pt;
  
  padding-bottom: 0;
  line-height: 1.1;
  text-align: left;
}

.c32 {
  display: flex;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  height: 15pt;
}

.c97 {
  padding-bottom: 0;
  line-height: 1;
  text-align: center;
  vertical-align: middle;
  text-wrap: wrap;
  transform: rotate(-90deg);
}

.c72 {
  line-height: 1;
  text-align: center;
  transform: rotate(-90deg);
}

.c91 {
  margin-left: 11.4pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c28 {
  margin-left: 5.4pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c63 {
  margin-left: 5.4pt;
  
  padding-bottom: 0;
  line-height: 1.0208333333333;
  text-align: left;
}

.c69 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
  height: 11pt;
}

.c5 {
  margin-left: 0.5pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: center;
}

.c93 {
  margin-left: 5.4pt;
  
  padding-bottom: 0;
  line-height: 1.0375;
  text-align: left;
}

.c107 {
  width: 100%;
  padding-left: 6pt;
  
  margin-bottom: 20pt;
  border-bottom: 1px solid #000;
  line-height: 1.1375;
  text-align: left;
}

.c50 {
  margin-left: 4.5pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c95 {
  margin-left: 0.5pt;
  
  padding-bottom: 0;
  line-height: 1.0375;
  text-align: center;
}

.c8 {
  padding-bottom: 0;
  line-height: 1.15;
  text-align: left;
  height: 11pt;
}

.c15 {
  padding-bottom: 0;
  line-height: 1;
  /* text-align: right; */
  margin-left: 4.5pt;
}

.c96 {
  margin-left: 4.4pt;
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c43 {
  margin-left: 4.5pt;
  
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c73 {
  padding-bottom: 0;
  line-height: 1;
  /* text-align: right; */
  margin-left: 4.4pt;
}

.c6 {
  margin-left: 5.4pt;
  
  padding-bottom: 0;
  line-height: 0.85;
  text-align: left;
}

.c1 {
  
  padding-bottom: 0;
  line-height: 1;
  text-align: center;
  height: 11pt;
}

.c106 {
  margin-left: 6.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c36 {
  margin-left: 249.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c12 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c33 {
  
  padding-bottom: 0;
  line-height: 0.06;
  text-align: left;
}

.c112 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c98 {
  margin-top: -12pt;
  margin-left: 6.6pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c60 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c99 {
  margin-left: 3.2pt;
  border-spacing: 0;
  border-collapse: collapse;
  margin-right: auto;
}

.c59 {
  text-align: center;
  transform: rotate(-90deg);
  vertical-align: middle;
}

.c82 {
  padding-bottom: 0;
  line-height: 1;
  text-align: left;
}

.c89 {
  background-color: #fff;
  width: 500pt;
  margin: 0 auto;
}

.c87 {
  padding: 0;
  margin: 0;
  list-style: none;
}

.c52 {
  margin-left: 43.2pt;
  text-indent: -31.6pt;
}

.c86 {
  margin-right: 7.8pt;
}

.c81 {
  height: 11pt;
}

.c19 {
  height: 13.4pt;
}

.c114 {
  margin-right: 0.1pt;
}

.c48 {
  height: 20.6pt;
}

.c101 {
  margin-right: 26.1pt;
}

.c18 {
  height: 14.3pt;
}

.c111 {
  margin-right: 33.6pt;
}

.c14 {
  height: 14.4pt;
}

.c109 {
  height: 11.2pt;
}

.c40 {
  height: 22pt;
}

.c29 {
  background-color: #bfbfbf;
}

.c115 {
  height: 22.4pt;
}

.title {
  color: #000;
  font-weight: 700;
  font-size: 36pt;
  padding-bottom: 6pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  text-align: left;
}

.subtitle {
  color: #666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  text-align: left;
}

li {
  color: #000;
  font-size: 11pt;
  font-family: "Arial";
}

p {
  margin: 0;
  /* color: #000; */
  font-size: 11pt;
  font-family: "Arial";
}
